/* eslint-disable */
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { RegisterOptions } from 'react-hook-form';

import { get } from '@utils/utils';

import EyeOffIcon from '@assets/icon-eye-off.svg';
import EyeOnIcon from '@assets/icon-eye-on.svg';

import styles from './css';
import IconCloseCircleFill from '@svg-icons/IconCloseCircleFill';

type Props = {
  label?: string;
  showLabel?: boolean;
  type?: 'text' | 'number' | 'password' | 'email' | 'tel';
  name?: string;
  placeHolder?: string;
  valueObj?: any;
  defaultValue?: string | null;
  autoComplete?: string;
  icon?: JSX.Element | null;
  register?: any;
  registerOptions?: RegisterOptions;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: ((event: Event) => void) | null;
  onBlur?: ((e: React.FocusEvent<HTMLInputElement>) => void) | null;
  onFocus?: ((e: React.FocusEvent<HTMLInputElement>) => void) | null;
  errors?: any;
  showRequiredLabel?: boolean;
  showDeleteButton?: boolean;
  readOnly?: boolean;
  isPlaceholderSameStyleOfValue?: boolean;
  underline?: boolean;
  autoFocus?: boolean;
  customRef?: React.RefObject<HTMLInputElement>;
  children?: React.ReactElement;
  testId?: string;
  isCopyPasteRestricted?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  enterKeyHint?: string;
  onClickDeleteButton?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Input = ({
  label,
  showLabel = true,
  type = 'text',
  name,
  placeHolder,
  valueObj = {},
  defaultValue,
  autoComplete = 'off',
  icon = null,
  register = () => {},
  registerOptions = {},
  onChange = () => {},
  onClick = null,
  onBlur = null,
  onFocus = null,
  errors = {},
  showRequiredLabel = false,
  showDeleteButton = false,
  readOnly = false,
  isPlaceholderSameStyleOfValue = false,
  underline = false,
  autoFocus = false,
  customRef,
  children,
  testId,
  isCopyPasteRestricted,
  onKeyDown = () => {},
  enterKeyHint = 'enter',
  onClickDeleteButton = () => {},
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('form');

  useEffect(() => {
    if (autoFocus) {
      const node = inputRef.current;
      if (node) {
        node.focus();
      }
    }
  }, []);

  const errorType = get(errors, `${name}.type`);
  let errorTranslationKey;
  if (errorType === 'pattern' || errorType === 'validate') {
    const names = name?.split('.') || [];
    errorTranslationKey = `formError.${errorType}.${names[names.length - 1]}`;
  } else if (errorType === 'manual') {
    errorTranslationKey = `formError.manual.${get(errors, `${name}.message`)}`;
  } else {
    errorTranslationKey = `formError.${errorType}`;
  }

  const inputType = type === 'password' && showPassword ? 'text' : type;

  const restrictCopyPaste = useMemo(() => {
    return isCopyPasteRestricted
      ? {
          onCopy: (e: React.ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            return false;
          },
          onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => {
            e.preventDefault();
            return false;
          },
        }
      : null;
  }, [isCopyPasteRestricted]);

  return (
    <div className="form-input">
      <label className="form-input-inner" htmlFor={name}>
        {label && showLabel && (
          <span className="form-label">
            {label}
            {showRequiredLabel && <em>{t('required')}</em>}
          </span>
        )}
        <div className={classNames('input-wrapper', { underline })}>
          {icon && <div className="input-icon">{icon}</div>}
          {children}
          <input
            className={classNames({
              'is-error': errorType,
              'read-only': readOnly,
              'is-place-holder-same-style-of-value':
                isPlaceholderSameStyleOfValue,
            })}
            type={inputType}
            name={name}
            id={name}
            placeholder={placeHolder}
            {...valueObj}
            defaultValue={defaultValue}
            autoComplete={autoComplete}
            ref={
              autoFocus
                ? inputRef
                : customRef || register({ ...registerOptions })
            }
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onClick={onClick}
            {...restrictCopyPaste}
            readOnly={readOnly}
            disabled={readOnly}
            data-testid={testId}
            onKeyDown={onKeyDown}
            enterKeyHint={enterKeyHint}
          />

          {type === 'password' && (
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="password-toggle-button"
            >
              {showPassword ? <EyeOnIcon /> : <EyeOffIcon />}
            </button>
          )}
          {type === 'text' && showDeleteButton && (
            <button
              type="button"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = '';
                }
                inputRef?.current?.focus();
                onClickDeleteButton();
              }}
              className="text-toggle-button"
            >
              <IconCloseCircleFill color="black400" />
            </button>
          )}

          {underline && <div className="round-border" />}
        </div>
      </label>

      {errorType && (
        <div className="form-error">
          {t(errorTranslationKey, {
            label: label ?? '',
            minLength: String(registerOptions.minLength),
            maxLength: String(registerOptions.maxLength),
          })}
        </div>
      )}

      <style jsx>{styles}</style>
    </div>
  );
};

export default Input;
